import React from "react"
import LayoutBasic from "../../components/LayoutBasic"
import BasicText from "../../components/BasicText"
import Info2column from "../../components/Info2column"
import CommonButton from "../../components/CommonButton"
import Seo from "../../components/Seo"
import Map01 from "../../images/interview_map_01_en.svg"
import Map02 from "../../images/interview_map_02_en.svg"
import Map03 from "../../images/interview_map_03_en.svg"
import FlagMm from "../../images/flag_mm.svg"
import FlagJp from "../../images/flag_jp.svg"
import FlagId from "../../images/fkag_id.svg"
import FlagVn from "../../images/flag_vn.svg"

import css from "./stylesheets/interview.module.scss"
import CenterContentLayout from "../../components/CenterContentLayout"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default ({ data }) => {
  return (
    <div className={css.InterviewTop}>
      <Seo title={`INTERVIEW`} lang={`en`} />
      <LayoutBasic lang={`en`} style={{ marginBottom: `0` }}>
        <CenterContentLayout>
          <BasicText>
            <h1>INTERVIEW</h1>
            <p>
              These are the interviews with those who are working as care
              workers in Japan. We interviewed care workers who had a variety of
              residence statuses. They are working in relationship pairs, such
              as, senior and junior, coworkers, and husband and wife. We visited
              their workplaces and accommodations.
            </p>
            <Info2column id={`interview-01`} noBorder={true}>
              <div className={`only-pc`}>
                <Link to={`/en/interview/1`}>
                  <Img fluid={data.img01.childImageSharp.fluid} />
                </Link>
              </div>
              <div>
                <hr />
                <p className={css.date}>{`2020.3.25`}</p>
                <h3>Teaching Each Other and Working Together</h3>

                <div className={`only-sp`}>
                  <Link to={`/en/interview/1`}>
                    <Img
                      fluid={data.img01.childImageSharp.fluid}
                      className={css.spOnlyImg}
                    />
                  </Link>
                </div>
                <p>
                  Tomoe Yamazaki
                  <small>（Age: 23）</small>× Zin Zin Moe
                  <small>（Age: 27）</small>
                </p>
                <div className={css.col2Styles}>
                  <div className={css.col2StylesLeft}>
                    <dl>
                      <dt>Birthplaces</dt>
                      <dd>
                        <img src={FlagJp} alt="Japan" className={`flag`} />
                        Japan ×{" "}
                        <img src={FlagMm} alt="Myanmar" className={`flag`} />
                        Myanmar
                      </dd>

                      <dt>Relationship</dt>
                      <dd>Coworkers</dd>
                    </dl>
                    <div className={css.commonButton + ` only-pc`}>
                      <CommonButton to={`/en/interview/1`} align={`left`}>
                        MORE
                      </CommonButton>
                    </div>
                  </div>
                  <div className={css.col2StylesRight}>
                    <img
                      style={{ width: `100%` }}
                      src={Map01}
                      alt="Sapporo City, Hokkaido"
                      width={392}
                      height={418}
                      className={css.mapImage}
                    />
                  </div>
                </div>
                <div className={`only-sp`}>
                  <CommonButton to={`/en/interview/1`}>MORE</CommonButton>
                </div>
              </div>
            </Info2column>

            <Info2column id={`interview-02`} noBorder={true}>
              <div className={`only-pc`}>
                <Link to={`/en/interview/2`}>
                  <Img fluid={data.img02.childImageSharp.fluid} />
                </Link>
              </div>
              <div>
                <hr />
                <p className={css.date}>{`2020.3.25`}</p>
                <h3>
                  Working and Self-Caring for Mental and Physical Conditions
                </h3>

                <div className={`only-sp`}>
                  <Link to={`/en/interview/2`}>
                    <Img
                      fluid={data.img02.childImageSharp.fluid}
                      className={css.spOnlyImg}
                    />
                  </Link>
                </div>

                <p>
                  Kenichi Saito
                  <small>（Age: 39）</small>× Nguyen Ngoc Thuy
                  <small>（Age: 26）</small>
                </p>
                <div className={css.col2Styles}>
                  <div className={css.col2StylesLeft}>
                    <dl>
                      <dt>Birthplaces</dt>
                      <dd>
                        <img src={FlagJp} alt="Japan" className={`flag`} />
                        Japan ×{" "}
                        <img src={FlagVn} alt="Vietnam" className={`flag`} />
                        Vietnam
                      </dd>

                      <dt>Relationship</dt>
                      <dd>Boss & Care Worker</dd>
                    </dl>
                    <div className={css.commonButton + ` only-pc`}>
                      <CommonButton to={`/en/interview/2`} align={`left`}>
                        MORE
                      </CommonButton>
                    </div>
                  </div>
                  <div className={css.col2StylesRight}>
                    <img
                      style={{ width: `100%` }}
                      src={Map02}
                      alt="Shiojiri City, Nagano"
                      width={392}
                      height={418}
                      className={css.mapImage}
                    />
                  </div>
                </div>
                <div className={`only-sp`}>
                  <CommonButton to={`/en/interview/2`}>MORE</CommonButton>
                </div>
              </div>
            </Info2column>

            <Info2column id={`interview-03`} noBorder={true}>
              <div className={`only-pc`}>
                <Link to={`/en/interview/3`}>
                  <Img fluid={data.img03.childImageSharp.fluid} />
                </Link>
              </div>
              <div>
                <hr />
                <p className={css.date}>{`2020.3.25`}</p>
                <h3>Children Growing at the Workplace</h3>

                <div className={`only-sp`}>
                  <Link to={`/en/interview/3`}>
                    <Img
                      fluid={data.img03.childImageSharp.fluid}
                      className={css.spOnlyImg}
                    />
                  </Link>
                </div>

                <p>
                  Ria Prestia Anggraini
                  <small>（Age: 33）</small>× Ucu Suhendri
                  <small>（Age: 33）</small>
                </p>
                <div className={css.col2Styles}>
                  <div className={css.col2StylesLeft}>
                    <dl>
                      <dt>Birthplaces</dt>
                      <dd>
                        <img src={FlagId} alt="Indonesia" className={`flag`} />
                        Indonesia ×{" "}
                        <img src={FlagId} alt="Indonesia" className={`flag`} />
                        Indonesia
                      </dd>

                      <dt>Relationship</dt>
                      <dd>Husband & Wife, Coworkers</dd>
                    </dl>
                    <div className={css.commonButton + ` only-pc`}>
                      <CommonButton to={`/en/interview/3`} align={`left`}>
                        MORE
                      </CommonButton>
                    </div>
                  </div>
                  <div className={css.col2StylesRight}>
                    <img
                      style={{ width: `100%` }}
                      src={Map03}
                      alt="Narita City, Chiba"
                      width={392}
                      height={418}
                      className={css.mapImage}
                    />
                  </div>
                </div>
                <div className={`only-sp`}>
                  <CommonButton to={`/en/interview/3`}>MORE</CommonButton>
                </div>
              </div>
            </Info2column>
          </BasicText>
        </CenterContentLayout>
      </LayoutBasic>
    </div>
  )
}
export const query = graphql`
  query {
    img01: file(relativePath: { eq: "interview/01/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img02: file(relativePath: { eq: "interview/02/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img03: file(relativePath: { eq: "interview/03/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
